import { render, staticRenderFns } from "./HealthQuestions.vue?vue&type=template&id=7f270808&scoped=true"
import script from "./HealthQuestions.vue?vue&type=script&lang=js"
export * from "./HealthQuestions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f270808",
  null
  
)

export default component.exports